import Config from '@/config';
import {api, ws, mutationFromAction} from './api';
import store from "@/store/index";
import router from "@/router";

const actions = {
    /**
     * Базовый метод, по идее, не должен вызываться вручную.
     * Используйте post() для отправки форм или кастомных некэшируемых запросов.
     * Используйте get() для отправки кэшируемых запросов.
     * Все остальные данные должны грузиться через fetchPage() в цикле загрузки страницы.
     * @param store
     * @param options
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    request({commit, state}, options) {
        if (Config.API_WITH_WS) return ws(options.action ? options.action : Config.API_DEFAULT_ACTION, options);
        else return api(options.action ? options.action : Config.API_DEFAULT_ACTION, options)
            .then((response) => {
                commit('setResponse', response);
                return response;
            });
    },

    /**
     * Для свободного обращения к API методом POST.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    post(store, params) {
        params = params || {};
        params.method = "POST";
        return actions.request(store, params);
    },

    /**
     * Для удаления записей методом DELETE.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    del(store, params) {
        params = params || {};
        params.method = "DELETE";
        return actions.request(store, params);
    },

    /**
     * Для свободного обращения к API методом GET.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    get(store, params) {
        params = params || {};
        params.method = "GET";
        return actions.request(store, params);
    },

    /**
     * Типовой запрос на действие на сервере.
     * @param store
     * @param options
     * @returns {Q.Promise<*>|*|Q.Promise<T>|PromiseLike<T>|Promise<T>}
     */
    action(store, options) {
        console.log("ACTION", options);

        // make local mutation
        const mutation = mutationFromAction(options.action);
        store.commit(mutation, options?.params);

        return actions.post(store, options);
    },

    /**
     * Типовой вызов API.
     * @param store
     * @param params
     * @returns {Q.Promise<*>|*|Q.Promise<T>|PromiseLike<T>|Promise<T>}
     */
    api(store, params) {
        store.state.isLoading = true;

        return store.dispatch('post', {
            action: params.action,
            params
        }, {root: true}).then((res) => {
            return res;
        })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            })
            .finally(() => {
                store.state.isLoading = false;
            });
    },

    /**
     * Загружает страницу в роутинге.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    fetchPage(store, params) {
        params = params || {};
        params.method = params.method ? params.method : "GET";
        return actions.request(store, params).then((result) => {
            store.commit('setPage', result);
        });
    },

    /**
     * Осуществляет переход на определенный роут.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    go(store, params) {
        console.log("GO", params);
        const route = {
            name: params?.params?.route,
            query: {r: Math.random()},
        }
        router.push(route);
    },

    /**
     * Показывает плашку "В разработке".
     * @param store
     * @param params
     * @returns {*}
     */
    soon(store, params) {
        return store.commit("soon");
    },
};

export default actions;

import store from "@/store";

export default [
    {
        path: '/',
        component: () => import('../components/layouts/layout-default'),
        children: [
            {
                path: '/',
                component: () => import('../components/group/group-content'),
                children: [
                    {
                        name: 'home',
                        path: '/',
                        component: () => import('../components/group/group-content-task'),
                        props: true,
                        meta: {
                            actions: [
                                {action: "user/tasks", subscribe: ["user"], params: {with1Task: true}},
                            ],
                            title: () => store.state.page.task.TITLE
                        },
                    },
                    {
                        name: 'home-task',
                        path: '/1/:taskAuth',
                        component: () => import('../components/group/group-content-task'),
                        props: true,
                        meta: {
                            actions: [
                                {action: "user/tasks", subscribe: ["user"]},
                                {action: "task/item", subscribe: ["task"], withAnon: true},
                            ],
                            title: () => store.state.page.task.TITLE
                        }
                    },
                ]
            },
            {
                path: '/',
                component: () => import('../components/group'),
                children: [
                    {
                        name: 'my',
                        path: '/my',
                        component: () => import('../components/group/group-content'),
                        meta: {
                            actions: [
                                {action: "user/tasks", subscribe: ["user"]},
                            ],
                            title: "Мои задачи"
                        },
                        children: [
                            {
                                name: 'my-task',
                                path: '/my/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "user/tasks", subscribe: ["user"]},
                                        {action: "task/item", subscribe: ["task"], withAnon: true},
                                    ],
                                    title: () => store.state.page.task.TITLE
                                }
                            },
                        ]
                    },
                    {
                        name: 'recent',
                        path: '/r',
                        component: () => import('../components/group/group-content'),
                        meta: {
                            actions: [
                                {action: "user/recent", subscribe: ["user"]},
                            ],
                            title: "Недавние задачи"
                        },
                        /*children: [
                            {
                                name: 'recent-task',
                                path: '/r/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "user/recent", subscribe: ["user"]},
                                        {action: "task/item", subscribe: ["task"], withAnon:true},
                                    ]
                                }
                            },
                        ]*/
                    },
                    {
                        name: 'group',
                        path: '/g/:groupAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {
                            actions: [
                                // TODO THIS IS UNSECURE TO SUBSCRIBE WITHOUT CHECKING VALID ACCESS !!!!!!!!!!
                                {action: "group/item", subscribe: ["group"]},
                            ],
                            title: () => store.state.page.context.TITLE
                        },
                        children: [
                            {
                                name: 'group-task',
                                path: '/g/:groupAuth/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        // TODO THIS IS UNSECURE TO SUBSCRIBE WITHOUT CHECKING VALID ACCESS !!!!!!!!!!
                                        {action: "group/item", subscribe: ["group"]},
                                        {action: "task/item", subscribe: ["task"], withAnon: true},
                                    ],
                                    title: () => store.state.page.task.TITLE
                                }
                            },
                        ]
                    },
                    {
                        name: 'topic',
                        path: '/c/:topicAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {
                            actions: [
                                // TODO THIS IS UNSECURE TO SUBSCRIBE WITHOUT CHECKING VALID ACCESS !!!!!!!!!!
                                {action: "topic/item", subscribe: ["topic"]},
                            ],
                            title: () => store.state.page.context.TITLE
                        },
                        children: [
                            {
                                name: 'topic-task',
                                path: '/c/:topicAuth/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        // TODO THIS IS UNSECURE TO SUBSCRIBE WITHOUT CHECKING VALID ACCESS !!!!!!!!!!
                                        {action: "topic/item", subscribe: ["topic"]},
                                        {action: "task/item", subscribe: ["task"], withAnon: true},
                                    ],
                                    title: () => store.state.page.task.TITLE
                                }
                            },
                        ]
                    },
                    {
                        name: 'prio',
                        path: '/p',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {action: "soon", params: {prio: true}},
                        children: [
                            {
                                name: 'prio-task-new',
                                path: '/p/new',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {api: "task/item"}
                            },
                            {
                                name: 'prio-task',
                                path: '/p/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {api: "task/item", params: {prio: true}}
                            },
                        ]
                    },
                    {
                        path: '/s',
                        name: 'search',
                        component: () => import('../components/group/group-content'),
                        meta: {
                            actions: [
                                {action: "user/search", query: {search: "s"}, subscribe: ["user"]}
                            ],
                        },
                        /*children: [
                            {
                                name: 'search-task',
                                path: '/s/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "user/search", query: {search: "s"}, subscribe: ["user"]},
                                        {action: "task/item", subscribe: ["task"], withAnon: true},
                                    ]
                                }
                            },
                        ]*/
                    },
                    {
                        name: 'users',
                        path: '/a',
                        component: () => import('../components/group/group-users'),
                        props: true,
                        meta: {action: "soon"}
                    },
                    {
                        name: 'assignee',
                        path: '/a/:assigneeAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {action: "soon", query: {tab: "tab"}},
                        children: [
                            {
                                name: 'assignee-task-new',
                                path: '/a/:assigneeAuth/new',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {api: "group-item", query: {tab: "tab"}}
                            },
                            {
                                name: 'assignee-task',
                                path: '/a/:assigneeAuth/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "user/tasks"},
                                        {action: "task/item"},
                                    ]
                                }
                            },
                        ]
                    },
                ]
            },
            {
                path: '/w',
                name: 'workspaces',
                component: () => import('../components/workspace'),
                props: true,
                meta: {api: "workspace-items"}
            },
            {
                path: '/:auth',
                redirect: {
                    name: 'group'
                },
            },
        ]
    },

    {
        path: '/_/auth',
        name: 'auth',
        component: () => import('../components/auth'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/_/auth-as',
        name: 'auth-as',
        component: () => import('../components/auth-as'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/_/auth-tg',
        name: 'auth-tg',
        component: () => import('../components/auth-tg'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/_/logout',
        name: 'logout',
        component: () => import('../components/logout'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/error/404',
        name: 'error404',
        component: () => import('../components/exception'),
        meta: {code: '404'}
    },
    {
        path: '*',
        name: 'error-unknown',
        component: () => import('../components/exception'),
        props: true,
    },
];

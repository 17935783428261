import Config from '@/config';
import Vue from "vue";
import {ws} from "../api";

const actions = {

    hello(store, options) {
        return ws(Config.WS_ACTION_HELLO, {params: {mutation: null}});
    },

    /**
     * Fetches group tasks.
     * @param store
     * @param params
     * @returns {*}
     */
    tasks(store, params) {
        return store.dispatch('get', {
            action: 'user/tasks',
            params
        }, {root: true});
    },

    /**
     * Sorts topic in a group.
     * @param store
     * @param params
     * @returns {*}
     */
    taskSort(store, params) {
        console.log("ACTION user/taskSort", params);

        // local change
        store.commit("userTaskSort", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'user/taskSort',
            params
        }, {root: true});
    },

    /**
     * GETs current session.
     * @param store
     * @param params
     * @returns {*}
     */
    session(store, params) {
        // skip if session already set
        if ( store.rootState.session.user ) return;

        //console.log("ACTION user/session", params);

        // local change
        //store.commit("userSession", params, {root: true});

        // remote change
        return store.dispatch('get', {
            action: 'user/session',
            params: {
                key: "session",
                ...params,
            }
        }, {root: true});
    },

    assigneeMenu(store, params) {
        return new Promise((resolve, reject) => {
            params.callback = (res) => {
                return resolve(res);
            };

            store.commit('uiItemMenu', {
                menu: store.rootState.ui.assigneeMenu,
                params
            }, {root: true});
        });

        /*
                let timeout = 0;
                if (store.state.assigneeMenuParams.isVisible) {
                    store.state.assigneeMenuParams.isVisible = false;
                    timeout = 150;
                }
                setTimeout(() => {
                    ///store.state.assigneeMenuParams.userMenuParams = params.userMenuParams,
                    store.state.assigneeMenuParams.user = params.user;
                    store.state.assigneeMenuParams.users = params.users;
                    store.state.assigneeMenuParams.usersOfGroup = params.usersOfGroup;
                    store.state.assigneeMenuParams.x = params.event?.clientX;
                    store.state.assigneeMenuParams.y = params.event?.clientY + 16;
                    store.state.assigneeMenuParams.isVisible = true;
                    store.state.assigneeMenuParams.callback = params.callback;
                }, timeout);*/
    },

    /*companionMenu(store, params) {
        return new Promise((resolve, reject) => {
            params.callback = (res) => {
                return resolve(res);
            };

            store.commit('uiItemMenu', {
                menu: store.rootState.ui.assigneeMenu,
                params
            }, {root: true});
        });
    },*/


    // TODO REVIEW ---------------

    sort(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-task-sort',
        }, {root: true});
    },

    update(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-update',
        }, {root: true});
    },

    user(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-user',
        }, {root: true});
    },

    group(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group',
        }, {root: true});
    },

    groupSort(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group-sort',
        }, {root: true});
    },

    groupLeave(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group-leave',
        }, {root: true});
    },



    refresh(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-refresh',
        }, {root: true});
    },
};

export default actions;

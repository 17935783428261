export const nick = (user) => {
	if ( !user ) return null;
	if ( user.nick ) return user.nick;
	if ( user.firstName && user.lastName ) return user.firstName + " " + user.lastName;
	if ( user.firstName ) return user.firstName;
	if ( user.lastName ) return user.lastName;
	return user.name;
};

export const fi = (user) => {
	if ( user.nick ) {
		const parts = user.nick.split(" ");
		if ( parts.length >=2 ) return parts[0].substring(0,1) + parts[1].substring(0,1);
		return user.nick.substring(0,2);
	}
	if ( user.firstName && user.lastName ) return user.firstName.substring(0,1) + user.lastName.substring(0,1);
	if ( user.firstName ) return user.firstName.substring(0,2);
	if ( user.lastName ) return user.lastName.substring(0,2);
	return user.name.substring(0,2);
};
import Vue from "vue";
import store from "@/store";
import Config from "@/config";
import {nick} from "@/utils/user";
import {iso2Date, iso2DDMMYYYY, iso2HHMM, mysql2Date} from "@/utils/date";
import router from "@/router";

/**
 * Base component.
 * Encapsulates some widely used computed variables and methods.
 */
export default {
    data() {
        return {}
    },
    computed: {
        hasData() {
            return this.session.isInited && this.page.isInited && !this.is403 && !this.is404;
        },

        // session --------------------

        session() {
            return this.$store.state.session;
        },
        user() {
            return this.session.user;
        },
        workspace() {
            return this.session.workspace;
        },
        onlineUsers() {
            return this.session.area?.onlineUsers?.filter(el => el.auth !== this.user?.auth);
        },
        /**
         * Current user has full access.
         * @returns {boolean}
         */
        isAuthorized() {
            //return this.$store.state.isAuthorized;
            return !this.isLimitedAccess;
        },
        /**
         * Current user has full access.
         * Alias for isAuthorized.
         * @returns {boolean}
         */
        isAuthed() {
            return this.isAuthorized;
        },
        isAnon() {
            return this.session.isAnon;
        },
        is403() {
            return this.session.isAnon && (
                !this.isRouteTask
            );
        },
        is404() {
            // todo
            return false;
        },

        // ui -------------------
        ui() {
            return this.$store.state.ui;
        },
        pending() {
            return this.ui.pending;
        },

        withGroupFullAccess() {
            return !this.pageGroup || this.pageGroup.isFullAccess;
        },
        withTodoFullAccess() {
            return !this.pageTodo || this.pageTodo.isFullAccess;
        },
        isLimitedAccess() {
            return this.isAnon || (
                this.context.isInited && !this.context.isFullAccess//!this.group && !this.topic && !this.isRouteMy
            );
        },
        isFullAccess() {
            return !this.isLimitedAccess;
            /*return (this.withGroupFullAccess && (!this.isRouteTask || this.withTodoFullAccess))
                || (this.isRoute1Task && this.withTodoFullAccess);*/
        },
        assignee() {
            return this.ui.context?.assignee;
        },
        search: {
            get() {
                return this.ui.search.value;
            },
            set(v) {
                this.$store.commit("uiSetSearch", v);//$store.state.ui.search.value = v;
            }
        },
        isSearching() {
            return this.ui.search.isSearching;
        },

        // area ---
        area() {
            return this.session.area;
        },
        workspaces() {
            return this.area?.workspaces;
        },
        groups() {
            return this.area?.groups;
        },
        topics() {
            return this.area?.topics;
        },
        users() {
            return this.area?.users;
        },

        // page -------------------
        page() {
            return this.$store.state.page;
        },
        tasks() {
            return this.page.tasks;
        },

        // context ---
        context() {
            //console.log("REQUESTING context", this.page.context);
            return this.page.context;
        },
        group() {
            return this.context.group;
        },
        topic() {
            return this.context.topic;
        },
        usersOfGroup() {
            return this.context.usersOfGroup;
        },
        tab: {
            get() {
                return this.context.tab
            },
            set(v) {
                return this.context.tab = v;
            }
        },

        // msgs -------------------
        msgs() {
            return this.page.msgs;
        },

        // task -------------------
        task() {
            return this.page.task;
        },


        // routing --------------------

        isRouteMy() {
            const thisUserAuth = this.$store.state.session.user?.auth;
            return (this.$route.name === "assignee" && thisUserAuth === this.$route.params.assigneeAuth)
                || (this.isRouteAssigneeTask && thisUserAuth === this.$route.params.assigneeAuth)
                || this.isRouteMyTask
                || this.isRouteMyTaskNew
                || (this.$route.name === "my" && !!thisUserAuth);
        },
        isRouteRecent() {
            return this.$route.name === "recent";
        },
        isRouteAssignee() {
            const thisUserAuth = this.$store.state.session.user?.auth;
            return (this.$route.name === "assignee"
                || this.isRouteAssigneeTask) && thisUserAuth !== this.$route.params.assigneeAuth;
        },
        isRoutePrio() {
            return this.$route.name === "prio" || this.isRoutePrioTodo || this.isRoutePrioTodoNew;
        },
        isRouteTask() {
            return this.isRouteTopicTask
                || this.isRouteGroupTask
                || this.isRouteMyTask
                || this.isRoute1Task
                || this.isRouteAssigneeTask
                || this.isRoutePrioTodo
                || this.isRouteSearchTodo
                || this.isRouteTaskNew;
        },
        isRouteTaskNew() {
            return this.isRouteTopicTaskNew
                || this.isRouteGroupTaskNew
                || this.isRouteMyTaskNew
                || this.isRouteAssigneeTaskNew
                || this.isRoutePrioTodoNew;
            //|| this.isRouteSearchTodoNew;
        },
        isRouteTopicTask() {
            return (this.$route.name === "topic-task" || this.$route.name === "topic-task-new");
        },
        isRouteTopicTaskNew() {
            return this.$route.name === "topic-task-new";
        },
        isRouteGroupTask() {
            return (this.$route.name === "group-task" || this.$route.name === "group-task-new");
        },
        isRouteGroupTaskNew() {
            return !!this.group && this.$route.name === "group-task-new";
        },
        isRouteAssigneeTask() {
            return this.$route.name === "assignee-task" || this.$route.name === "assignee-task-new";
        },
        isRouteAssigneeTaskNew() {
            return this.$route.name === "assignee-task-new";
        },
        isRouteMyTask() {
            return this.$route.name === "my-task";
        },
        isRouteMyTaskNew() {
            return this.$route.name === "my-task-new";
        },
        isRoutePrioTodo() {
            return this.$route.name === "prio-task";
        },
        isRoutePrioTodoNew() {
            return this.$route.name === "prio-task-new";
        },
        isRouteSearchTodo() {
            return this.$route.name === "search-todo";
        },
        isRouteUsers() {
            return this.$route.name === "users";
        },
        isRouteSearch() {
            return this.$route.name === "search" || this.isRouteSearchTodo;
        },
        isRouteTopic() {
            return this.$route.name === "topic" || this.isRouteTopicTask;
        },
        isRouteGroup() {
            return this.$route.name === "group" || this.isRouteGroupTask;
        },
        isRoute1Task() {
            return this.$route.name === "home" || this.$route.name === "home-task";
        },
        parentListRoute() {
            if (this.isRouteGroupTask) return {
                name: "group",
                params: {groupAuth: this.group.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteTopicTask) return {
                name: "topic",
                params: {topicAuth: this.topic.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteAssigneeTask) return {
                name: "assignee",
                params: {assigneeAuth: this.assignee.auth},
                query: {tab: this.$route.query?.tab}
            };
            if (this.isRouteSearchTodo) return {
                name: "search",
                query: {s: this.$route.query?.s, tab: this.$route.query?.tab}
            };
        }
    },
    /*	beforeRouteUpdate(to, from, next){
            console.log("beforeRouteUpdate", to, from);
            //initAuthorization(to);
            next();
        }*/
    methods: {
        do(action, params) {
            return this.$store.dispatch("action", {
                action,
                params
            });
        },
        dispatch(name, params) {
            return this.$store.dispatch(name, params);
        },
        commit(name, payload) {
            return this.$store.commit(name, payload);
        },
        toggleTaskDone(item) {
            if (item.doneAt || item.isDone) {
                this.$store.dispatch("action", {
                    action: "task/update",
                    params: {
                        taskAuth: item.auth,
                        params: {
                            isDone: false,
                            isActive: true,
                            doneAt: null
                        }
                    }
                });
            } else {
                this.$store.dispatch("action", {
                    action: "task/update",
                    params: {
                        taskAuth: item.auth,
                        params: {
                            isDone: true,
                            isActive: false,
                        }
                    }
                });
            }
        },
        isTaskDone(item) {
            if ( !item ) return false;
            return !!(item.isDone || item.doneAt);
        },
        isTaskDelayed(item) {
            if ( !item ) return false;
            return !!(item.isDelayed || !!item.delayedTill);
        },
        delayedDateText(item) {
            if ( !item ) return false;
            let text = null;
            if (item.delayedTill) {
                let hm = iso2HHMM(item.delayedTill);
                if (hm === "00:00") hm = "";
                else hm = " " + hm;
                text = iso2DDMMYYYY(item.delayedTill) + hm;
            }
            else if ( item.isDelayed ) text = "Отложена";
            return text;
        },
        nickOf(user) {
            return nick(user);
        },
        showDialogTaskDelay(item) {
            this.$store.dispatch("task/dialogDelay", {
                item,
            });
        },
    }
}
import striptags from "striptags";

/**
 * Returns array of paragraphs <p>content</p> from text.
 * @param text
 * @param withHRs
 * @returns {*[]}
 */
export const findPs = (text, withHRs=false) => {
	if ( !text ) return [];
	let matches;
	if ( withHRs ) matches = text.matchAll(/(<p[^>]*>.*?<\/p>|<hr[^>]*>)/igs);
	else matches = text.matchAll(/<p[^>]*>.*?<\/p>/igs);
	matches = [...matches].map(el=>el[0]);
	return matches;
}

/**
 * Returns content from <p>content</p>.
 * @param text
 * @returns {*}
 */
export const pContent = (text) => {
	const ps = findPs(text);
	let line = ps[0];
	line = line.replace(/<p[^>]*>(.*?)<\/p>/is, "$1");
	return line;
}

export const isHR = (text) => {
	return text?.match(/^<hr[^>]*>$/);
}

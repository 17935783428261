import {customAlphabet} from 'nanoid';
import config from "@/config";
import storage from "@/utils/storage";

export function obtainClientAuth(withResetId = false, withResetTab = false) {
	let clientId = storage.getString(config.STORAGE_CLIENT_ID_TOKEN);
	if (withResetId || !clientId || clientId?.length !== config.CLIENT_ID_LENGTH) {
		const nanoid = customAlphabet(config.CLIENT_ID_ALPHABET, config.CLIENT_ID_LENGTH);
		clientId = nanoid();
		storage.set(config.STORAGE_CLIENT_ID_TOKEN, clientId);
	}

	let tabId = storage.getSessionString(config.STORAGE_TAB_ID_TOKEN);
	if (withResetTab || !tabId || tabId?.length !== config.CLIENT_ID_LENGTH) {
		const nanoid = customAlphabet(config.CLIENT_ID_ALPHABET, config.CLIENT_ID_LENGTH);
		tabId = nanoid();
		storage.setSession(config.STORAGE_TAB_ID_TOKEN, tabId);
	}

	return clientId + "_" + tabId;
}

export function clientIdFromClientAuth(clientAuth) {
	return clientAuth?.replace(/^(.{16})([._].{16})?$/, "$1");
}

export function obtainWorkspaceAuth() {
	return storage.getString(config.STORAGE_WORKSPACE_AUTH);
}
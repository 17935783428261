<template>
	<router-link :to="to" class="group-topics-item" :class="itemClass" :style="itemStyle"
				 :id="'topic-'+(item.auth||'general')">
		<div class="d-flex align-center">
			<v-icon v-if="item.hasPrio" small class="mr-2" :color="iconColor">mdi-fire</v-icon>
			<v-icon v-else-if="item.icon" small class="mr-2" :color="iconColor">{{ item.icon }}</v-icon>
			<span class="group-topics-item__name">{{ item.name }}</span>
		</div>

		<span v-if="isAuthed">
			<v-menu v-if="item.id" offset-x left>
					<template v-slot:activator="{ on, attrs }">
						<v-icon small v-bind="attrs"
								v-on="on">mdi-dots-vertical
						</v-icon>
					</template>

					<!--					<v-card>
											some card here
										</v-card>-->

					<v-list>
						<v-list-item link
									 @click="event=>onAssignee(event)">
							<v-icon small class="mr-2">mdi-account</v-icon>
							<v-list-item-content>
								<v-list-item-title>{{ assignee }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider/>
						<v-list-item link @click="onDelete">
							<v-icon small class="mr-2">mdi-close</v-icon>
							<v-list-item-content>
								<v-list-item-title>Удалить</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
		</span>

	</router-link>
</template>

<script>
export default {
	name: "group-topics-item",
	props: {
		item: {},
		users: {},
		currentGroup: {},
		currentTopic: {},
	},
	data: () => ({}),
	computed: {
		to() {
			if (this.item?.auth) return {
				name: "topic",
				params: {auth: this.item.groupAuth, topicAuth: this.item.auth}
			};
			return {
				name: "group",
				params: {auth: this.item.groupAuth}
			};
			//this.$emit("click", this.item);
		},
		itemClass() {
			const c = [];
			if (this.isCurrent) c.push("--current");
			return c;
		},
		itemStyle() {
			const s = [];
			if (this.item.bg) s.push({backgroundColor: this.item.bg});
			return s;
		},
		iconColor() {
			if (this.item.hasPrio) return "red";
			if (this.isCurrent) return "grey";
			return "grey lighten-1"
		},
		assignee() {
			const user = this.users?.find(el => el.id === this.item.userId);
			return user?.name || "нет ответственного";
		},
		isAuthed() {
			return this.$store.state.isAuthorized;
		},
		isCurrent() {
			return this.item.groupId === this.currentGroup?.id
				&& this.item.id === this.currentTopic?.id;
		}
	},
	watch: {},
	methods: {
		onClick(e) {
			if (this.isCurrent) return;
			this.$emit("click", this.item);
		},
		onDragEnd(event) {
			//console.log("onDragEnd", event);
			//this.$emit("click", this.item);
		},
		onDelete() {
			this.$emit("delete", this.item);
		},
		onAssignee(event) {
			//console.log("onAssignee", event);
			this.$emit("assignee", this.item, event);
		},
	},
}
</script>

<style lang="scss">
.group-topics-item {
	position: relative;
	list-style: none;
	cursor: pointer;
	//border-bottom: 1px solid map-get($grey, "lighten-3");
	margin: 0;
	padding: 0 0.5em 0 2.75em;
	height: 2em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include transition();

	&:hover {
		background: map-get($grey, "lighten-2");

		button {
			opacity: 1;
		}
	}

	&.sortable-chosen {
		background: map-get($grey, "lighten-2");
	}

	&.ghost {
		//opacity: 0.25;
	}

	&__name {
		//flex-basis: 90%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		border-bottom: 1px dotted transparent;
	}

	&.--done {
		opacity: $opacity-done;

		.group-topics-item__name {
			text-decoration: line-through;
		}
	}

	&.--collapsed:not(.--done) {
		.group-topics-item__name {
			border-bottom: 1px dotted map-get($grey, "lighten-1");
		}
	}

	&.--current {
		background: $white !important;
		color: $black;
	}

	button {
		opacity: 0;
	}

	.group-topics-draggable {
		margin-left: 24px;
	}
}
</style>

<template>
	<div class="group-topics-draggable">
<!--		<draggable v-if="isAuthedToGeneralTopic" zone="topic-general" :enabled="isMD">
			<group-topics-item :item="generalTopic"
							   :current-group="currentGroup"
							   :current-topic="currentTopic"
							   :users="users"/>
		</draggable>-->

		<draggable v-for="(item, index) of list" :enabled="isMD && isAuthorized"
				   :key="'todo'+item.id+'_'+index"
				   :data="item"
				   :zone="zone"
				   class="group-topics-draggable__item" :class="itemClass(item)"
				   @drop="onDrop">
			<group-topics-item :item="item"
							   :current-group="currentGroup"
							   :current-topic="currentTopic"
							   :users="users"
							   @assignee="(item, e)=>$emit('assignee', item, e)"
							   @delete="$emit('delete', item)"/>
		</draggable>
	</div>
</template>

<script>
import draggable from '@/components/elements/draggable';
import GroupTopicsItem from './group-topics-item';
import vp from "@/mixins/vp";
import base from "@/mixins/base";

export default {
	name: "group-topics-draggable",
	mixins: [vp, base],
	components: {draggable, GroupTopicsItem},
	props: {
		group: {},
		items: {},
		users: {},
		currentGroup: {},
		currentTopic: {},
	},
	data: () => ({
	}),
	computed: {
		list() {
			return this.topics?.filter(el=>el.groupId===this.group.id);
		},
		zone() {
			return "topics";// + this.group.id;
		},
		generalTopic() {
			/*const items = actualItemsFrom(this.items
				// remove empty - todo move to backend
				.filter(el => !!clearName(el.name))
				// apply groups & topic
				.filter(el => !this.groupIds?.length || this.groupIds.includes(el.groupId))
				.filter(el => !el.topicId), this.group, this.tab.id);*/
			return {
				groupId: this.group.id,
				groupAuth: this.group.auth,
				name: "Обсуждение",
				icon: "mdi-folder-text-outline",
				//hasPrio: !!items.find(el => el.prio)
			}
		},
		isAuthedToGeneralTopic() {
			console.log("current topic", this.currentTopic)
			return !this.currentTopic || this.$store.state.session.userGroups?.findIndex(el => el.auth === this.currentGroup.auth) >= 0;
		}
	},
	watch: {
	},
	methods: {
		initList() {
			if ( this.items?.length ) {
				this.list = [...this.items];
				this.list.sort((a, b) => {
					if (this.group?.topicSort) {
						// take group sorting
						const topicSort = typeof this.group.topicSort === "string" ? JSON.parse(this.group.topicSort) : this.group.topicSort;
						if (typeof topicSort === "object" && topicSort.length) {
							const aIndex = topicSort.findIndex(id => id === a.id);
							const bIndex = topicSort.findIndex(id => id === b.id);
							return aIndex - bIndex;
						}
					}

					if (a.updatedAt > b.updatedAt) return -1;
					if (a.updatedAt < b.updatedAt) return 1;
				});
			}
			else this.list = [];
		},
		itemClass(item) {
			const c = [];
			if (item.id === this.current?.id) c.push("--current");
			return c;
		},
		onDrop(source, destination) {
			console.log("onDrop", source, destination);
			const destinationIndex = this.list.findIndex(el => el.id === destination.data?.id);
			if (source.zone === this.zone) {
				// topic d-n-d sorting
				const sourceIndex = this.list.findIndex(el => el.id === source.data?.id);
				//console.log("sourceIndex", sourceIndex);
				/*source = this.list[sourceIndex];
				this.list.splice(sourceIndex, 1);
				this.list.splice(destinationIndex, 0, source);*/
				const auths = this.list.map(el=>el.auth);
				source = auths[sourceIndex];
				auths.splice(sourceIndex, 1);
				auths.splice(destinationIndex, 0, source);

				return this.$store.dispatch('group/topicSort', {
					groupAuth: this.group.auth,
					topicSort: auths
				});

			} else if (source.zone === "todos") {
				// processed in group-todos
			}
		},
	},
}
</script>

<style lang="scss">
.group-topics-draggable {
	&__item.--current {
		background: map-get($light-blue, "lighten-5");
	}
}
</style>

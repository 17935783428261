import Config from '@/config';
import FileUtils from '@/utils/file';

const mutations = {
    fileUpload: (state, payload) => {
        console.log("MUTATION file/upload", payload);

        // reset previously uploaded files
        state.page.uploadedFiles = null;

        // after creation - call this
        payload.mutation = "fileAppend";

        // after creation - broadcast to subscribers of this
        const broadcasts = [{
            entity: "task",
            auth: payload.taskAuth,
        }];
        broadcasts.push({
            entity: "group",
            auth: payload.groupAuth,
        });
        if (payload.topicAuth) broadcasts.push({
            entity: "topic",
            auth: payload.topicAuth,
        });
        payload.broadcasts = broadcasts;
        console.log("broadcasts", broadcasts);
    },
    fileAppend: (state, payload) => {
        console.log("MUTATION file/append", payload);

        // doing nothing here... as editor ws service should do everything on collaboration
    },
};

export default mutations;

import Config from '@/config';

const actions = {
    /**
     * Submits a msg.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    /*create(store, params) {
        return store.dispatch('post', {
            action: 'msg-create',
            params
        }, {root: true})
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            });
    },*/
    upload(store, params) {
        return store.dispatch('post', {
            action: 'file-create',
            params
        }, {root: true});
    },
};

export default actions;

import Config from '@/config';
import {customAlphabet} from 'nanoid';
import storage from "@/utils/storage";

export default {
    state: {
        lastDragElement: null,
        lastDropElement: null
    }
}

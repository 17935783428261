import Config from '@/config';

const actions = {
    /**
     * Fetches group tasks.
     * @param store
     * @param params
     * @returns {*}
     */
    /*tasks(store, params) {
        return store.dispatch('get', {
            action: 'topic/tasks',
            params
        }, {root: true});
    },*/

    assign(store, params) {
        console.log("ACTION topic/assign", params);

        // local change
        store.commit("topicAssign", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'topic/assign',
            params
        }, {root: true});
    },

    delete(store, params) {
        console.log("ACTION topic/delete", params);

        // local change
        store.commit("topicDelete", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'topic/delete',
            params
        }, {root: true});
    },

    // TODO REVIEW ---------------------


    /**
     * Submits a msg.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    update(store, params) {
        store.state.isSending = true;

        /*return store.dispatch('post', {
            action: 'topic-update',
            params
        }, {root: true}).then((res) => {
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
            store.state.isSending = false;
        });*/
    },

};

export default actions;
